import React from "react";

import { DefaultLayout } from "../components/layout/defaultLayout";
import { SampleWrapper } from "../components/wrapper/sampleWrapper";

const SupportPage = () => {
  return (
    <DefaultLayout pageTitle="サポート" headerTitle="SUPPORT">
      <SampleWrapper txt="サポートページ" />
    </DefaultLayout>
  );
};

export default SupportPage;
